<template>
  <div>
    <Header 
      title="Gerenciamento de Pedidos"
      subtitle="Visualize os últimos pedidos realizados no site."
    />
    <div class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li @click="orderFocus = order.data(), $modal.show('order')" v-for="(order, index) in orders" :key="index">
            <div class="flex items-center justify-between px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1">
                <p class="font-medium text-sm text-primary truncate">#{{ order.id.substring(0, 6).toUpperCase() }}  •  {{ order.data().items.length }} itens</p>
                <div class="hidden sm:flex sm:items-center text-xs font-medium text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-1 h-3 w-3" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" /></svg>
                  <span>{{ order.data().name }}</span>
                </div>
              </div>  
              <p class="text-xs font-medium text-gray-600">{{ new Date(order.data().createdAt).toLocaleString() }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-7 w-7 text-gray-500 rounded-full hover:bg-gray-200 p-1" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" /></svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="order" class="z-45 text-sm text-gray-700">
      <p class="mb-2 text-base font-medium text-gray-800">Informações do Pedido</p>
      <p class="mb-2"><span class="text-primary font-medium">Criado em:</span> {{ new Date(orderFocus.createdAt).toLocaleString() }}</p>
      <p v-if="orderFocus.use" class="mb-2"><span class="text-primary font-medium">Uso:</span> {{ orderFocus.use }}</p>
      <p class="mb-2"><span class="text-primary font-medium">Comprador:</span> {{ orderFocus.name }}</p>
      <p v-if="orderFocus.address"><span class="text-primary font-medium">Endereço:</span> {{ `${orderFocus.address.Street}, ${orderFocus.address.Number}. ${orderFocus.address.Neighborhood}, ${orderFocus.address.City} / ${orderFocus.address.State}. ${orderFocus.address.Complement ? orderFocus.address.Complement + ', ' : ''}${orderFocus.address.Cep}. ` }}</p>
      <ul class="divide-y divide-gray-200">
        <li v-for="(item, index) in orderFocus.items" :key="index" class="py-4 flex">
          <img class="h-10 w-10 rounded-lg" :src="item.image" :alt="item.title">
          <div class="ml-3">
            <p class="text-sm font-medium text-gray-900">
              <span class="mr-1 mb-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary text-white">
                {{ item.amount }}x
              </span>
              {{ item.title }} 
            </p>
            <p class="text-sm font-medium ml-0.5 text-gray-600">{{ item.price * item.amount | currency }}</p>
          </div>
        </li>
      </ul>
      <p v-if="orderFocus.frete" class="mb-2"><span class="text-primary font-medium">Frete:</span> {{ orderFocus.frete.value | currency }} ({{ orderFocus.frete.name}})</p>
      <p v-if="orderFocus.items" class="mb-2"><span class="text-primary font-medium">Total:</span> {{ orderFocus.items.reduce((ac, cr) => ac + (cr.amount * cr.price), 0) + orderFocus.frete.value | currency }} ({{ orderFocus.payment }})</p>
    </t-modal>
  </div>
</template>

<script>
import { ordersColl } from '@/firebase'

export default {
  components: { Header: () => import('@/components/layout/header') },

  data() { return { orders: [], orderFocus: '' } },

  mounted() { this.getOrders(this.$router.currentRoute?.params?.id) },

  methods: {
    async getOrders(id = '') {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (id) { await ordersColl.where('id', '==', id).orderBy('createdAt', 'desc').limit(50).get().then((result) => { this.orders = result.docs }).catch(() => { this.$toast.error('Falha ao obter os pedidos, tente novamente') }) }
      else { await ordersColl.orderBy('createdAt', 'desc').limit(20).get().then((result) => { this.orders = result.docs }).catch(() => { this.$toast.error('Falha ao obter os pedidos, tente novamente') }) }
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  },

  filters: {
    // elapsedTime(value) {

    //   const units = {
    //     year  : 24 * 60 * 60 * 1000 * 365,
    //     month : 24 * 60 * 60 * 1000 * 365/12,
    //     day   : 24 * 60 * 60 * 1000,
    //     hour  : 60 * 60 * 1000,
    //     minute: 60 * 1000,
    //     second: 1000
    //   }
  
    //   const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' })
    //   const elapsed = new Date(value) - new Date()

    //   for (var u in units) 
    //     if (Math.abs(elapsed) > units[u] || u == 'second') 
    //       return rtf.format(Math.round(elapsed/units[u]), u)
    // },
    currency(value) {
      if (isNaN(value)) { return value }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      return formatter.format(value)
    }
  }
}
</script>